

class stickyAtcBar extends HTMLElement {
  constructor () {
    super()

    const selectors = {
      buyButtons: '[data-add-to-cart]',
      productForm: '[data-product-section]',
    }

    this.classes = {
      hidden: 'is-show',
    }

    this.productForm = document.querySelector(selectors.productForm)
    this.buyButtons = this.querySelector(selectors.buyButtons)

    if (this.buyButtons && this.productForm) {
      this.buyButtons.addEventListener('click', this.scrollToVariantSelector.bind(this))
    }

    const buyButtonsObserver = new IntersectionObserver(
      ([{ isIntersecting: visible }]) => {
        if (visible) {
          this.hideBar(this)
        } else {
          this.showBar(this)
        }
      },
    )

    const productSectionObserver = new IntersectionObserver(
      ([{ isIntersecting: visible }]) => {
        if (visible) {
          buyButtonsObserver.observe(this.buyButtons)
        } else {
          buyButtonsObserver.disconnect()
          this.showBar(this)
        }
      },
      { threshold: 0.5 },
    )

    this.productForm && productSectionObserver.observe(this.productForm)
  }

  scrollToVariantSelector () {
    this.productForm.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  showBar (el) {
    el.classList.add(this.classes.hidden)
  }

  hideBar (el) {
    el.classList.remove(this.classes.hidden)
  }
}

window.customElements.define('sticky-atc-bar', stickyAtcBar)
